<div class="d-flex login-bg" >
  <div class="name-user">
    <span class="ml-10 name-user-hello mr-4">{{'welcome' | translate}}, {{modelUser.name}}</span>
  </div>
  <div>
    <a href="javascript:void(0)" (click)="onLogout()" fxLayoutAlign="center center" class="exit-login">
      <span class="color-white">Sair</span>
    </a>
  </div>
</div>
