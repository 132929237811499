import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export class AccountGraph {
  active?: Maybe<Scalars['Boolean']>;
  balance?: Maybe<Scalars['Float']>;
  campaignId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userCreatedId?: Maybe<Scalars['String']>;
  userDeletedId?: Maybe<Scalars['String']>;
  userUpdatedId?: Maybe<Scalars['String']>;
};

export class ActivityGraph {
  afsActive?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  displacement?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  force?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  participant?: Maybe<ParticipantGraph>;
  participantId?: Maybe<Scalars['String']>;
  pdv?: Maybe<PdvGraph>;
  pdvId?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subregion?: Maybe<SubRegionGraph>;
  subregionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class AdminRoleCurrent {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class AuthResponse {
  access_token?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isFlagged?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  participantId?: Maybe<Scalars['String']>;
  showModalRegulation?: Maybe<Scalars['Boolean']>;
  showModalTerm?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export class BannerApp {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  urlImage?: Maybe<Scalars['String']>;
};

export class BannerAppPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<BannerApp>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class ClientCampaign {
  active?: Maybe<Scalars['Boolean']>;
  catalog?: Maybe<Scalars['Boolean']>;
  checkout?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class ContestCampaign {
  activePeriod?: Maybe<Scalars['String']>;
  awards?: Maybe<Array<ContestCampaignAward>>;
  channelRegion?: Maybe<Array<Scalars['String']>>;
  end?: Maybe<Scalars['DateTime']>;
  goals?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageCount?: Maybe<Scalars['Float']>;
  mechanic?: Maybe<Scalars['String']>;
  metaPoints?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  urlImage?: Maybe<Scalars['String']>;
};

export class ContestCampaignAward {
  description?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export class ContestCategories {
  brand?: Maybe<Array<ContestCategoriesBrand>>;
  description?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export class ContestCategoriesBrand {
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export class ContestExecutionTypes {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
};

export class ContestInputModels {
  page?: InputMaybe<Scalars['Float']>;
};

export class ContestModel {
  descriptionMechanicMerchan?: Maybe<Scalars['String']>;
  descriptionMechanicWinnerLeader?: Maybe<Scalars['String']>;
  descriptionMechanicWinnerManager?: Maybe<Scalars['String']>;
  descriptionMechanicWinnerMerchan?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  hasPodium?: Maybe<Scalars['Boolean']>;
  hexColor1?: Maybe<Scalars['String']>;
  hexColor2?: Maybe<Scalars['String']>;
  hexColor3?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  moreInformation?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  podiumType?: Maybe<Scalars['String']>;
  qtdPodiumPictures?: Maybe<Scalars['Float']>;
  qtdWinningPictures?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['Float']>;
  stage3PrizeGlobalValue?: Maybe<Scalars['String']>;
  stage3PrizeWithRanking?: Maybe<Scalars['Float']>;
  stage4PrizeGlobalValue?: Maybe<Scalars['String']>;
  stage4PrizeWithRanking?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  votingStatus?: Maybe<Scalars['String']>;
  winningType?: Maybe<Scalars['String']>;
};

export class ContestModelId {
  categories?: Maybe<Array<ContestCategories>>;
  descriptionMechanicMerchan?: Maybe<Scalars['String']>;
  descriptionMechanicWinnerLeader?: Maybe<Scalars['String']>;
  descriptionMechanicWinnerManager?: Maybe<Scalars['String']>;
  descriptionMechanicWinnerMerchan?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  executionTypes?: Maybe<Array<ContestExecutionTypes>>;
  hasPodium?: Maybe<Scalars['Float']>;
  hexColor1?: Maybe<Scalars['String']>;
  hexColor2?: Maybe<Scalars['String']>;
  hexColor3?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  moreInformation?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  podiumType?: Maybe<Scalars['String']>;
  qtdPodiumPictures?: Maybe<Scalars['Float']>;
  qtdWinningPictures?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['Float']>;
  stage3PrizeGlobalValue?: Maybe<Scalars['String']>;
  stage3PrizeWithRanking?: Maybe<Scalars['Float']>;
  stage4PrizeGlobalValue?: Maybe<Scalars['String']>;
  stage4PrizeWithRanking?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  votingStatus?: Maybe<Scalars['String']>;
  winningType?: Maybe<Scalars['String']>;
};

export class ContestPagination {
  currentPage?: Maybe<Scalars['Float']>;
  nextPage?: Maybe<Scalars['Boolean']>;
  pageSize?: Maybe<Scalars['Float']>;
  previousPage?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Array<ContestModel>>;
  totalCount?: Maybe<Scalars['Float']>;
  totalPage?: Maybe<Scalars['Float']>;
};

export class ContestPaginationModel {
  currentPage?: Maybe<Scalars['Float']>;
  nextPage?: Maybe<Scalars['Boolean']>;
  pageSize?: Maybe<Scalars['Float']>;
  previousPage?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Array<MyContestsModel>>;
  totalCount?: Maybe<Scalars['Float']>;
  totalPage?: Maybe<Scalars['Float']>;
};

export class ContestParticipantCategories {
  brandId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export class ContestParticipantContest {
  campaignId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  descriptionMechanicMerchan?: Maybe<Scalars['String']>;
  descriptionMechanicWinnerLeader?: Maybe<Scalars['String']>;
  descriptionMechanicWinnerManager?: Maybe<Scalars['String']>;
  descriptionMechanicWinnerMerchan?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  finishedStage1At?: Maybe<Scalars['String']>;
  finishedStage2At?: Maybe<Scalars['String']>;
  finishedStage3At?: Maybe<Scalars['String']>;
  finishedStage4At?: Maybe<Scalars['String']>;
  hasPodium?: Maybe<Scalars['Boolean']>;
  hexColor1?: Maybe<Scalars['String']>;
  hexColor2?: Maybe<Scalars['String']>;
  hexColor3?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  moderatorPhotosCount?: Maybe<Scalars['String']>;
  moreInformation?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photosCount?: Maybe<Scalars['String']>;
  podiumType?: Maybe<Scalars['String']>;
  qtdPodiumPictures?: Maybe<Scalars['String']>;
  qtdWinningPictures?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['Float']>;
  stage3PrizeGlobalValue?: Maybe<Scalars['String']>;
  stage3PrizeWithRanking?: Maybe<Stage3PrizeWithRanking>;
  stage4PrizeGlobalValue?: Maybe<Scalars['String']>;
  stage4PrizeWithRanking?: Maybe<Stage3PrizeWithRanking>;
  startDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userCreatedId?: Maybe<Scalars['String']>;
  userDeletedId?: Maybe<Scalars['String']>;
  userFinishedStage1Id?: Maybe<Scalars['String']>;
  userFinishedStage2Id?: Maybe<Scalars['String']>;
  userFinishedStage3Id?: Maybe<Scalars['String']>;
  userFinishedStage4Id?: Maybe<Scalars['String']>;
  userUpdatedId?: Maybe<Scalars['String']>;
  votingStatus?: Maybe<Scalars['String']>;
  winningType?: Maybe<Scalars['String']>;
};

export class ContestParticipantExecutionType {
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
};

export class ContestParticipantOne {
  age?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  childrenCount?: Maybe<Scalars['Float']>;
  civilStatus?: Maybe<Scalars['String']>;
  codeSap?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  genre?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  regional?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  subregion?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export class ContestParticipantPerformanceModel {
  blobIndex?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<ContestParticipantCategories>>;
  contest?: Maybe<ContestParticipantContest>;
  contestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  executionType?: Maybe<ContestParticipantExecutionType>;
  executionTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  participants?: Maybe<Array<ContestParticipantsGroup>>;
  pdv?: Maybe<ContestParticipantSalesPoints>;
  pictures?: Maybe<Array<Scalars['String']>>;
  subregionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userCreated?: Maybe<ContestParticipantUserCreated>;
  userCreatedId?: Maybe<Scalars['String']>;
  validation?: Maybe<ContestParticipantValidation>;
  votes?: Maybe<Array<Scalars['String']>>;
};

export class ContestParticipantSalesPoints {
  address?: Maybe<Scalars['String']>;
  canal?: Maybe<Scalars['String']>;
  cat?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  codeSoldBroker?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subRegion?: Maybe<Scalars['String']>;
  subcanal?: Maybe<Scalars['String']>;
  subregion?: Maybe<ContestParticipantSubRegion>;
  subregionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userCreatedId?: Maybe<Scalars['String']>;
  userDeletedId?: Maybe<Scalars['String']>;
  userUpdatedId?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export class ContestParticipantSubRegion {
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export class ContestParticipantUserCreated {
  codeForget?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailVerifiedAt?: Maybe<Scalars['String']>;
  externalPushNotificationCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inactivatedAt?: Maybe<Scalars['String']>;
  isFlagged?: Maybe<Scalars['Float']>;
  isModedator?: Maybe<UserIsModerator>;
  isVisibleBalance?: Maybe<Scalars['Float']>;
  jobTitle?: Maybe<Scalars['String']>;
  mfaCredentialId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  participantId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['String']>;
  userUpdatedId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export class ContestParticipantValidation {
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  denyReason?: Maybe<Scalars['String']>;
  galleryId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Float']>;
  positionStage2?: Maybe<Scalars['String']>;
  positionStage3?: Maybe<Scalars['String']>;
  positionStage4?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userCreatedId?: Maybe<Scalars['String']>;
  userDeletedId?: Maybe<Scalars['String']>;
  userUpdatedId?: Maybe<Scalars['String']>;
  userValidatedId?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['String']>;
};

export class ContestParticipantsGroup {
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  galleryId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  participant?: Maybe<ContestParticipantOne>;
  participantId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export class CreateBannerAppInput {
  end: Scalars['DateTime'];
  link?: InputMaybe<Scalars['String']>;
  start: Scalars['DateTime'];
  title: Scalars['String'];
};

export class CreateHighlightInput {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
  title: Scalars['String'];
};

export class CreateHighlightStoryInput {
  highlightId: Scalars['String'];
};

export class CurrentUser {
  access_token?: Maybe<Scalars['String']>;
  currentUser?: Maybe<ActivityGraph>;
};

export class CurrentUserAdmin {
  access_token?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isFlagged?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  participantId?: Maybe<Scalars['String']>;
  role?: Maybe<AdminRoleCurrent>;
  showModalRegulation?: Maybe<Scalars['Boolean']>;
  showModelTerm?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export class DataPost {
  data?: Maybe<Array<PostModel>>;
  limit?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export class DefaultMessage {
  message: Scalars['String'];
  status: Scalars['Int'];
};

export class ExtractListMoviment {
  month?: Maybe<Scalars['String']>;
  moviments?: Maybe<Array<ExtractMoviments>>;
  year?: Maybe<Scalars['String']>;
};

export class ExtractMovimentPagination {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<ExtractListMoviment>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class ExtractMoviments {
  activatedAt?: Maybe<Scalars['DateTime']>;
  active?: Maybe<Scalars['Boolean']>;
  balance?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userCreatedId?: Maybe<Scalars['String']>;
  userDeletedId?: Maybe<Scalars['String']>;
  userUpdatedId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export class Highlight {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  highlightStories?: Maybe<Array<HighlightStory>>;
  id: Scalars['ID'];
  start?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  urlImage?: Maybe<Scalars['String']>;
};

export class HighlightPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Highlight>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class HighlightStory {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  highlight?: Maybe<Highlight>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  urlImage?: Maybe<Scalars['String']>;
};

export class MediaItems {
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export class MediaModel {
  active?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  items?: Maybe<Array<MediaItems>>;
  startDate?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export class MediaPagination {
  currentPage?: Maybe<Scalars['Float']>;
  nextPage?: Maybe<Scalars['Boolean']>;
  pageSize?: Maybe<Scalars['Float']>;
  previousPage?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Array<MediaModel>>;
  totalCount?: Maybe<Scalars['Float']>;
  totalPage?: Maybe<Scalars['Float']>;
};

export class Mutation {
  /** Accept Regulation */
  AcceptRegulation: Scalars['Boolean'];
  /** Accept Term */
  AcceptTerm: Scalars['Boolean'];
  /** Active User */
  ActiveUser: UserReadDtoGraph;
  /** Change Password */
  ChangePassword: Scalars['String'];
  /** Login with username and password */
  Login: AuthResponse;
  /** Read All Notifications */
  ReadAllNotifications: DefaultMessage;
  /** Read Notifications */
  ReadNotifications: DefaultMessage;
  /** Reset Password Change Password */
  ResetPasswordChangePassword: Scalars['String'];
  /** Reset Password Request Code */
  ResetPasswordRequestCode: Scalars['String'];
  /** Reset Password Validate Code */
  ResetPasswordValidateCode: Scalars['String'];
  createBanner: BannerApp;
  /** Create new Highlight */
  createHighlight: Highlight;
  /** Create new Highlight Story */
  createHighlightStory: HighlightStory;
  /** Remove Highlight By Id */
  deleteHighlight: DefaultMessage;
  /** Remove Highlight Story By Id */
  deleteHighlightStory: DefaultMessage;
  removeBanner: DefaultMessage;
  updateBanner: BannerApp;
  /** Update Highlight */
  updateHighlight: Highlight;
};


export class MutationAcceptRegulationArgs {
  regulationId: Scalars['String'];
};


export class MutationAcceptTermArgs {
  termId: Scalars['String'];
};


export class MutationActiveUserArgs {
  user: UserActiveRegisterGraph;
};


export class MutationChangePasswordArgs {
  password: Scalars['String'];
};


export class MutationLoginArgs {
  password: Scalars['String'];
  username: Scalars['String'];
};


export class MutationReadNotificationsArgs {
  notificationId: Scalars['String'];
};


export class MutationResetPasswordChangePasswordArgs {
  code: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};


export class MutationResetPasswordRequestCodeArgs {
  email: Scalars['String'];
};


export class MutationResetPasswordValidateCodeArgs {
  code: Scalars['String'];
  email: Scalars['String'];
};


export class MutationCreateBannerArgs {
  data: CreateBannerAppInput;
};


export class MutationCreateHighlightArgs {
  data: CreateHighlightInput;
};


export class MutationCreateHighlightStoryArgs {
  data: CreateHighlightStoryInput;
};


export class MutationDeleteHighlightArgs {
  id: Scalars['String'];
};


export class MutationDeleteHighlightStoryArgs {
  id: Scalars['String'];
};


export class MutationRemoveBannerArgs {
  id: Scalars['String'];
};


export class MutationUpdateBannerArgs {
  data: UpdateBannerAppInput;
};


export class MutationUpdateHighlightArgs {
  data: UpdateHighlightInput;
};

export class MyContestsModel {
  activePeriod?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageCount?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  participantsCount?: Maybe<Scalars['Float']>;
  urlImage?: Maybe<Scalars['String']>;
};

export class NespointInputGraph {
  channel?: InputMaybe<Scalars['String']>;
  contestCategoryId?: InputMaybe<Scalars['String']>;
  contestId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  executionTypeId?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
  pdvId?: InputMaybe<Scalars['String']>;
  regions?: InputMaybe<Array<Scalars['String']>>;
  sold?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  subregions?: InputMaybe<Array<Scalars['String']>>;
};

export class NespointsGraph {
  achieved?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export class NotificationModel {
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notification?: Maybe<Notifications>;
  notificationId?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userCreatedId?: Maybe<Scalars['String']>;
  userDeletedId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userUpdatedId?: Maybe<Scalars['String']>;
};

export class NotificationPagination {
  currentPage?: Maybe<Scalars['Float']>;
  nextPage?: Maybe<Scalars['Boolean']>;
  pageSize?: Maybe<Scalars['Float']>;
  previousPage?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Array<NotificationModel>>;
  totalCount?: Maybe<Scalars['Float']>;
  totalPage?: Maybe<Scalars['Float']>;
};

export class Notifications {
  active?: Maybe<Scalars['Boolean']>;
  automatic?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  hasSent?: Maybe<Scalars['Float']>;
  hyperlink?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userCreatedId?: Maybe<Scalars['String']>;
};

export class Participant {
  name: Scalars['String'];
};

export class ParticipantAddressGraph {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  complement?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  participant?: InputMaybe<ParticipantDtoGraph>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export class ParticipantDtoGraph {
  active?: InputMaybe<Scalars['String']>;
  addresses?: InputMaybe<Array<ParticipantAddressGraph>>;
  branch?: InputMaybe<Scalars['String']>;
  codeSap?: InputMaybe<Scalars['String']>;
  cpf?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  isVisibleBalance?: InputMaybe<Scalars['Float']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  pdv?: InputMaybe<PdvDtoGraph>;
  phone?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  regional?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  subregion?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export class ParticipantGraph {
  age?: Maybe<Scalars['Float']>;
  branch?: Maybe<Scalars['String']>;
  childrenCount?: Maybe<Scalars['Float']>;
  civilStatus?: Maybe<Scalars['String']>;
  codeSap?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  dob?: Maybe<Scalars['DateTime']>;
  genre?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  regional?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  subregion?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class ParticipantReadDtoGraph {
  branch?: Maybe<Scalars['String']>;
  codeSap?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pdv?: Maybe<PdvGraph>;
  phone?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  regional?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<CurrentUserAdmin>;
};

export class PdvDtoGraph {
  address?: InputMaybe<Scalars['String']>;
  canal?: InputMaybe<Scalars['String']>;
  cat?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  codeSoldBroker?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  index?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['String']>;
  long?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  subRegion?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export class PdvGraph {
  address?: Maybe<Scalars['String']>;
  canal?: Maybe<Scalars['String']>;
  cat?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  codeSoldBroker?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  district?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subRegion?: Maybe<Scalars['String']>;
  subcanal?: Maybe<Scalars['String']>;
  subregionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userCreatedId?: Maybe<Scalars['String']>;
  userDeletedId?: Maybe<Scalars['String']>;
  userUpdatedId?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export class PostFindOne {
  active?: Maybe<Scalars['Float']>;
  alternativeText?: Maybe<Scalars['String']>;
  amountComment?: Maybe<Scalars['Float']>;
  amountLike?: Maybe<Scalars['Float']>;
  comments?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  liked?: Maybe<Scalars['Float']>;
  midia?: Maybe<Scalars['String']>;
  postProfile?: Maybe<PostProfile>;
  relations?: Maybe<PostRelations>;
  startDate?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class PostModel {
  active?: Maybe<Scalars['Float']>;
  amountComment?: Maybe<Scalars['String']>;
  amountLike?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  liked?: Maybe<Scalars['Float']>;
  likedAt?: Maybe<Scalars['String']>;
  post?: Maybe<Posts>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visualized?: Maybe<Scalars['Float']>;
  visualizedAt?: Maybe<Scalars['String']>;
};

export class PostProfile {
  active?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class PostProfileFind {
  active?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userCreatedId?: Maybe<Scalars['String']>;
  userDeletedId?: Maybe<Scalars['String']>;
  userUpdatedId?: Maybe<Scalars['String']>;
};

export class PostProfilesPagination {
  currentPage?: Maybe<Scalars['Float']>;
  nextPage?: Maybe<Scalars['Boolean']>;
  pageSize?: Maybe<Scalars['Float']>;
  previousPage?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Array<PostProfileFind>>;
  totalCount?: Maybe<Scalars['Float']>;
  totalPage?: Maybe<Scalars['Float']>;
};

export class PostRelations {
  jobTitles?: Maybe<Array<Scalars['String']>>;
  regions?: Maybe<Array<Scalars['String']>>;
  subregions?: Maybe<Array<Scalars['String']>>;
};

export class Posts {
  active?: Maybe<Scalars['Float']>;
  alternativeText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  midia?: Maybe<Scalars['String']>;
  postProfile?: Maybe<PostProfile>;
  startDate?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class Query {
  /** Contest Participant Performance */
  ContestParticipantPerformance: Array<ContestParticipantPerformanceModel>;
  /** Current User Admin */
  CurrentUserAdmin: CurrentUserAdmin;
  /** Current User Venca */
  CurrentUserVenca: CurrentUser;
  /** Executions Count */
  ExecutionsCount: Scalars['Float'];
  /** Extract Detail */
  ExtratcDetail: ExtractMovimentPagination;
  /** First Access */
  FirstAccess: UserReadDtoGraph;
  /** Get Contest */
  GetContest: ContestPagination;
  /** Get Contest By Id */
  GetContestById: ContestModel;
  /** Get Contest By Participant */
  GetContestByParticipant: ContestPaginationModel;
  /** Get Contest Campaign By Id */
  GetContestCampaignById: ContestCampaign;
  /** Get Contest Categories */
  GetContestCategories: ContestModelId;
  /** Get Media By Sales Points Id */
  GetMediaBySalesPointsId: MediaPagination;
  /** Get Medias */
  GetMedias: MediaPagination;
  /** Get Notifications */
  GetNotifications: NotificationPagination;
  /** Get Post By Post Profile Id */
  GetPostByPostProfileId: PostFindOne;
  /** Get Post Profile */
  GetPostProfile: PostProfilesPagination;
  /** Get Post Profile By Id */
  GetPostProfileById: PostProfileFind;
  /** Get Posts */
  GetPosts: DataPost;
  /** Get Sales Points */
  GetSalesPoints: SalesPointsPagination;
  /** Get Sales Points By Participant */
  GetSalesPointsByParticipant: SalesPointsParticipantPagination;
  /** Get User Extract */
  GetUserExtract: UserExtract;
  /** Nespoints */
  Nespoints: NespointsGraph;
  /** Return All Banners For Admin */
  adminBanners: BannerAppPage;
  /** Return All Highlight for Admin */
  adminHighlight: HighlightPage;
  banner: BannerApp;
  /** Return All Banners */
  banners: Array<BannerApp>;
  campaignVenca: ClientCampaign;
  /** Return Highlight By ID */
  highlight: Highlight;
  /** Return Highlight Story By ID */
  highlightStory: HighlightStory;
  /** Return All Highlights */
  highlights: Array<Highlight>;
  participants: Array<Participant>;
};


export class QueryContestParticipantPerformanceArgs {
  page: Scalars['Float'];
  participantId: Scalars['String'];
};


export class QueryExtratcDetailArgs {
  dataFinal: Scalars['DateTime'];
  dataInicial: Scalars['DateTime'];
  page: Scalars['Float'];
  tipo: Scalars['String'];
};


export class QueryFirstAccessArgs {
  email: Scalars['String'];
};


export class QueryGetContestArgs {
  page: Scalars['Float'];
};


export class QueryGetContestByIdArgs {
  contestId: Scalars['String'];
};


export class QueryGetContestByParticipantArgs {
  data: ContestInputModels;
};


export class QueryGetContestCampaignByIdArgs {
  contestId: Scalars['String'];
};


export class QueryGetContestCategoriesArgs {
  contestId: Scalars['String'];
};


export class QueryGetMediaBySalesPointsIdArgs {
  page: Scalars['Float'];
  salesPointId: Scalars['String'];
};


export class QueryGetMediasArgs {
  page: Scalars['Float'];
};


export class QueryGetNotificationsArgs {
  page: Scalars['Float'];
};


export class QueryGetPostByPostProfileIdArgs {
  postProfileId: Scalars['String'];
};


export class QueryGetPostProfileArgs {
  page: Scalars['Float'];
};


export class QueryGetPostProfileByIdArgs {
  postProfileId: Scalars['String'];
};


export class QueryGetPostsArgs {
  page: Scalars['Float'];
};


export class QueryGetSalesPointsArgs {
  page: Scalars['Float'];
};


export class QueryGetSalesPointsByParticipantArgs {
  page: Scalars['Float'];
  participantId: Scalars['String'];
};


export class QueryNespointsArgs {
  filter: NespointInputGraph;
};


export class QueryAdminBannersArgs {
  data: SearchBannerAppInput;
};


export class QueryAdminHighlightArgs {
  data: SearchHighlightInput;
};


export class QueryBannerArgs {
  id: Scalars['String'];
};


export class QueryHighlightArgs {
  id: Scalars['String'];
};


export class QueryHighlightStoryArgs {
  id: Scalars['String'];
};

export class RoleGraph {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class SalesPointsByParticipantModel {
  canal?: Maybe<Scalars['String']>;
  codeSoldBroker?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  subRegion?: Maybe<Scalars['String']>;
  subcanal?: Maybe<Scalars['String']>;
  subregionId?: Maybe<Scalars['String']>;
};

export class SalesPointsModel {
  address?: Maybe<Scalars['String']>;
  canal?: Maybe<Scalars['String']>;
  cat?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  codeSoldBroker?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subRegion?: Maybe<Scalars['String']>;
  subcanal?: Maybe<Scalars['String']>;
  subregionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userCreatedId?: Maybe<Scalars['String']>;
  userDeletedId?: Maybe<Scalars['String']>;
  userUpdatedId?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export class SalesPointsPagination {
  currentPage?: Maybe<Scalars['Float']>;
  nextPage?: Maybe<Scalars['Boolean']>;
  pageSize?: Maybe<Scalars['Float']>;
  previousPage?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Array<SalesPointsModel>>;
  totalCount?: Maybe<Scalars['Float']>;
  totalPage?: Maybe<Scalars['Float']>;
};

export class SalesPointsParticipantPagination {
  currentPage?: Maybe<Scalars['Float']>;
  nextPage?: Maybe<Scalars['Boolean']>;
  pageSize?: Maybe<Scalars['Float']>;
  previousPage?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Array<SalesPointsByParticipantModel>>;
  totalCount?: Maybe<Scalars['Float']>;
  totalPage?: Maybe<Scalars['Float']>;
};

export class SearchBannerAppInput {
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchHighlightInput {
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class Stage3PrizeWithRanking {
  data?: Maybe<Array<Scalars['Float']>>;
  type?: Maybe<Scalars['String']>;
};

export class SubRegionGraph {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class UpdateBannerAppInput {
  end?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  link?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateHighlightInput {
  end?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class UserActiveRegisterGraph {
  addresses?: InputMaybe<Array<ParticipantAddressGraph>>;
  cpf?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export class UserExtract {
  bairro?: Maybe<Scalars['String']>;
  celular?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  cidade?: Maybe<Scalars['String']>;
  complemento?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  dataNascimento?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  endereco?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numero?: Maybe<Scalars['Float']>;
  saldo?: Maybe<Scalars['Float']>;
  telefone?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export class UserIsModerator {
  data?: Maybe<Array<Scalars['Float']>>;
  type?: Maybe<Scalars['String']>;
};

export class UserParticipant {
  age?: Maybe<Scalars['Float']>;
  branch?: Maybe<Scalars['String']>;
  childrenCount?: Maybe<Scalars['Float']>;
  civilStatus?: Maybe<Scalars['String']>;
  codeSap?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  genre?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  regional?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  subregion?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export class UserReadDtoGraph {
  account?: Maybe<AccountGraph>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isFlagged?: Maybe<Scalars['Float']>;
  isModerator?: Maybe<Scalars['Float']>;
  isVisibleBalance?: Maybe<Scalars['Float']>;
  login?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  participant?: Maybe<ParticipantReadDtoGraph>;
  participantId?: Maybe<Scalars['String']>;
  role?: Maybe<RoleGraph>;
  status?: Maybe<Scalars['Float']>;
};

export class UserRoleCurrent {
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type DefaultMessageFragment = { message: string, status: number };

export type HighlightStoryFragment = { id: string, urlImage?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null };

export type HighlightFragment = { id: string, title?: string | null, urlImage?: string | null, start?: any | null, end?: any | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, highlightStories?: Array<{ id: string, urlImage?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null }> | null };

export type HighlightPageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, title?: string | null, urlImage?: string | null, start?: any | null, end?: any | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, highlightStories?: Array<{ id: string, urlImage?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null }> | null }> | null };

export type BannerAppFragment = { id: string, title?: string | null, urlImage?: string | null, start?: any | null, link?: string | null, end?: any | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null };

export type BannerAppPageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, title?: string | null, urlImage?: string | null, start?: any | null, link?: string | null, end?: any | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null }> | null };

export type AuthResponseFragment = { access_token?: string | null, id?: string | null, username?: string | null, name?: string | null, email?: string | null, updatedAt?: any | null, nickname?: string | null, participantId?: string | null, showModalRegulation?: boolean | null, showModalTerm?: boolean | null, isFlagged?: boolean | null };

export type CurrentUserFragment = { access_token?: string | null, currentUser?: { id?: string | null, pdvId?: string | null, subregionId?: string | null, participantId?: string | null, date?: any | null, duration?: number | null, displacement?: number | null, status?: string | null, afsActive?: string | null, plate?: string | null, force?: number | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, participant?: { id?: string | null, name?: string | null, type?: string | null, phone?: string | null, age?: number | null, cpf?: string | null } | null, pdv?: { id?: string | null, name?: string | null, address?: string | null, canal?: string | null, cat?: string | null, customer?: string | null } | null, subregion?: { id?: string | null, name?: string | null, regionId?: string | null } | null } | null };

export type ActivityGraphFragment = { id?: string | null, pdvId?: string | null, subregionId?: string | null, participantId?: string | null, date?: any | null, duration?: number | null, displacement?: number | null, status?: string | null, afsActive?: string | null, plate?: string | null, force?: number | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, participant?: { id?: string | null, name?: string | null, type?: string | null, phone?: string | null, age?: number | null, cpf?: string | null } | null, pdv?: { id?: string | null, name?: string | null, address?: string | null, canal?: string | null, cat?: string | null, customer?: string | null } | null, subregion?: { id?: string | null, name?: string | null, regionId?: string | null } | null };

export type CurrentUserAdminFragment = { id?: string | null, username?: string | null, name?: string | null, email?: string | null, status?: number | null, updatedAt?: any | null, nickname?: string | null, participantId?: string | null, showModalRegulation?: boolean | null, showModelTerm?: boolean | null, isFlagged?: number | null, role?: { name?: string | null, id?: string | null } | null };

export type AdminBannersQueryVariables = Exact<{
  input: SearchBannerAppInput;
}>;


export type AdminBannersQuery = { adminBanners: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, title?: string | null, urlImage?: string | null, start?: any | null, link?: string | null, end?: any | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null }> | null } };

export type CreateBannerMutationVariables = Exact<{
  data: CreateBannerAppInput;
}>;


export type CreateBannerMutation = { createBanner: { id: string, title?: string | null, urlImage?: string | null, start?: any | null, link?: string | null, end?: any | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type UpdateBannerMutationVariables = Exact<{
  data: UpdateBannerAppInput;
}>;


export type UpdateBannerMutation = { updateBanner: { id: string, title?: string | null, urlImage?: string | null, start?: any | null, link?: string | null, end?: any | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type RemoveBannerMutationVariables = Exact<{
  data: Scalars['String'];
}>;


export type RemoveBannerMutation = { removeBanner: { message: string, status: number } };

export type HighlightsQueryVariables = Exact<{ [key: string]: never; }>;


export type HighlightsQuery = { highlights: Array<{ id: string, title?: string | null, urlImage?: string | null, start?: any | null, end?: any | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, highlightStories?: Array<{ id: string, urlImage?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null }> | null }> };

export type AdminHighlightQueryVariables = Exact<{
  input: SearchHighlightInput;
}>;


export type AdminHighlightQuery = { adminHighlight: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, title?: string | null, urlImage?: string | null, start?: any | null, end?: any | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, highlightStories?: Array<{ id: string, urlImage?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null }> | null }> | null } };

export type CreateHighlightMutationVariables = Exact<{
  input: CreateHighlightInput;
}>;


export type CreateHighlightMutation = { createHighlight: { id: string, title?: string | null, urlImage?: string | null, start?: any | null, end?: any | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, highlightStories?: Array<{ id: string, urlImage?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null }> | null } };

export type UpdateHighlightMutationVariables = Exact<{
  input: UpdateHighlightInput;
}>;


export type UpdateHighlightMutation = { updateHighlight: { id: string, title?: string | null, urlImage?: string | null, start?: any | null, end?: any | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, highlightStories?: Array<{ id: string, urlImage?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null }> | null } };

export type DeleteHighlightMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type DeleteHighlightMutation = { deleteHighlight: { message: string, status: number } };

export type CreateHighlightStoryMutationVariables = Exact<{
  input: CreateHighlightStoryInput;
}>;


export type CreateHighlightStoryMutation = { createHighlightStory: { id: string, urlImage?: string | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null } };

export type DeleteHighlightStoryMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type DeleteHighlightStoryMutation = { deleteHighlightStory: { message: string, status: number } };

export type CurrentUserVencaQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserVencaQuery = { CurrentUserVenca: { access_token?: string | null, currentUser?: { id?: string | null, pdvId?: string | null, subregionId?: string | null, participantId?: string | null, date?: any | null, duration?: number | null, displacement?: number | null, status?: string | null, afsActive?: string | null, plate?: string | null, force?: number | null, createdAt?: any | null, updatedAt?: any | null, deletedAt?: any | null, participant?: { id?: string | null, name?: string | null, type?: string | null, phone?: string | null, age?: number | null, cpf?: string | null } | null, pdv?: { id?: string | null, name?: string | null, address?: string | null, canal?: string | null, cat?: string | null, customer?: string | null } | null, subregion?: { id?: string | null, name?: string | null, regionId?: string | null } | null } | null } };

export type CurrentUserAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserAdminQuery = { CurrentUserAdmin: { access_token?: string | null, id?: string | null, username?: string | null, name?: string | null, email?: string | null, status?: number | null, updatedAt?: any | null, nickname?: string | null, participantId?: string | null, showModalRegulation?: boolean | null, showModelTerm?: boolean | null, isFlagged?: number | null, role?: { name?: string | null, id?: string | null } | null } };

export type LoginMutationVariables = Exact<{
  data: Scalars['String'];
  pass: Scalars['String'];
}>;


export type LoginMutation = { Login: { access_token?: string | null, id?: string | null, username?: string | null, name?: string | null, email?: string | null, updatedAt?: any | null, nickname?: string | null, participantId?: string | null, showModalRegulation?: boolean | null, showModalTerm?: boolean | null, isFlagged?: boolean | null } };

export type ResetPasswordRequestCodeMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordRequestCodeMutation = { ResetPasswordRequestCode: string };

export type ResetPasswordValidateCodeMutationVariables = Exact<{
  code: Scalars['String'];
  email: Scalars['String'];
}>;


export type ResetPasswordValidateCodeMutation = { ResetPasswordValidateCode: string };

export type ResetPasswordChangePasswordMutationVariables = Exact<{
  password: Scalars['String'];
  code: Scalars['String'];
  email: Scalars['String'];
}>;


export type ResetPasswordChangePasswordMutation = { ResetPasswordChangePassword: string };

export type ChangePasswordMutationVariables = Exact<{
  password: Scalars['String'];
}>;


export type ChangePasswordMutation = { ChangePassword: string };

export const DefaultMessageFragmentDoc = gql`
    fragment DefaultMessage on DefaultMessage {
  message
  status
}
    `;
export const HighlightStoryFragmentDoc = gql`
    fragment HighlightStory on HighlightStory {
  id
  urlImage
  createdAt
  updatedAt
  deletedAt
}
    `;
export const HighlightFragmentDoc = gql`
    fragment Highlight on Highlight {
  id
  title
  urlImage
  start
  end
  createdAt
  updatedAt
  deletedAt
  highlightStories {
    ...HighlightStory
  }
}
    ${HighlightStoryFragmentDoc}`;
export const HighlightPageFragmentDoc = gql`
    fragment HighlightPage on HighlightPage {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...Highlight
  }
}
    ${HighlightFragmentDoc}`;
export const BannerAppFragmentDoc = gql`
    fragment BannerApp on BannerApp {
  id
  title
  urlImage
  start
  link
  end
  createdAt
  updatedAt
  deletedAt
}
    `;
export const BannerAppPageFragmentDoc = gql`
    fragment BannerAppPage on BannerAppPage {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...BannerApp
  }
}
    ${BannerAppFragmentDoc}`;
export const AuthResponseFragmentDoc = gql`
    fragment AuthResponse on AuthResponse {
  access_token
  id
  username
  name
  email
  updatedAt
  nickname
  participantId
  showModalRegulation
  showModalTerm
  isFlagged
}
    `;
export const ActivityGraphFragmentDoc = gql`
    fragment ActivityGraph on ActivityGraph {
  id
  pdvId
  subregionId
  participantId
  date
  duration
  displacement
  status
  afsActive
  plate
  force
  createdAt
  updatedAt
  deletedAt
  participant {
    id
    name
    type
    phone
    age
    cpf
  }
  pdv {
    id
    name
    address
    canal
    cat
    customer
  }
  subregion {
    id
    name
    regionId
  }
}
    `;
export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on CurrentUser {
  access_token
  currentUser {
    ...ActivityGraph
  }
}
    ${ActivityGraphFragmentDoc}`;
export const CurrentUserAdminFragmentDoc = gql`
    fragment CurrentUserAdmin on CurrentUserAdmin {
  id
  username
  name
  email
  status
  updatedAt
  nickname
  participantId
  role {
    name
    id
  }
  showModalRegulation
  showModelTerm
  isFlagged
}
    `;
export const AdminBannersDocument = gql`
    query adminBanners($input: SearchBannerAppInput!) {
  adminBanners(data: $input) {
    ...BannerAppPage
  }
}
    ${BannerAppPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminBannersGQL extends Apollo.Query<AdminBannersQuery, AdminBannersQueryVariables> {
    document = AdminBannersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBannerDocument = gql`
    mutation createBanner($data: CreateBannerAppInput!) {
  createBanner(data: $data) {
    ...BannerApp
  }
}
    ${BannerAppFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBannerGQL extends Apollo.Mutation<CreateBannerMutation, CreateBannerMutationVariables> {
    document = CreateBannerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBannerDocument = gql`
    mutation updateBanner($data: UpdateBannerAppInput!) {
  updateBanner(data: $data) {
    ...BannerApp
  }
}
    ${BannerAppFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBannerGQL extends Apollo.Mutation<UpdateBannerMutation, UpdateBannerMutationVariables> {
    document = UpdateBannerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveBannerDocument = gql`
    mutation removeBanner($data: String!) {
  removeBanner(id: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveBannerGQL extends Apollo.Mutation<RemoveBannerMutation, RemoveBannerMutationVariables> {
    document = RemoveBannerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HighlightsDocument = gql`
    query highlights {
  highlights {
    ...Highlight
  }
}
    ${HighlightFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class HighlightsGQL extends Apollo.Query<HighlightsQuery, HighlightsQueryVariables> {
    document = HighlightsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AdminHighlightDocument = gql`
    query adminHighlight($input: SearchHighlightInput!) {
  adminHighlight(data: $input) {
    ...HighlightPage
  }
}
    ${HighlightPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminHighlightGQL extends Apollo.Query<AdminHighlightQuery, AdminHighlightQueryVariables> {
    document = AdminHighlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateHighlightDocument = gql`
    mutation createHighlight($input: CreateHighlightInput!) {
  createHighlight(data: $input) {
    ...Highlight
  }
}
    ${HighlightFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateHighlightGQL extends Apollo.Mutation<CreateHighlightMutation, CreateHighlightMutationVariables> {
    document = CreateHighlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHighlightDocument = gql`
    mutation updateHighlight($input: UpdateHighlightInput!) {
  updateHighlight(data: $input) {
    ...Highlight
  }
}
    ${HighlightFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHighlightGQL extends Apollo.Mutation<UpdateHighlightMutation, UpdateHighlightMutationVariables> {
    document = UpdateHighlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteHighlightDocument = gql`
    mutation deleteHighlight($input: String!) {
  deleteHighlight(id: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteHighlightGQL extends Apollo.Mutation<DeleteHighlightMutation, DeleteHighlightMutationVariables> {
    document = DeleteHighlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateHighlightStoryDocument = gql`
    mutation createHighlightStory($input: CreateHighlightStoryInput!) {
  createHighlightStory(data: $input) {
    ...HighlightStory
  }
}
    ${HighlightStoryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateHighlightStoryGQL extends Apollo.Mutation<CreateHighlightStoryMutation, CreateHighlightStoryMutationVariables> {
    document = CreateHighlightStoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteHighlightStoryDocument = gql`
    mutation deleteHighlightStory($input: String!) {
  deleteHighlightStory(id: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteHighlightStoryGQL extends Apollo.Mutation<DeleteHighlightStoryMutation, DeleteHighlightStoryMutationVariables> {
    document = DeleteHighlightStoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserVencaDocument = gql`
    query CurrentUserVenca {
  CurrentUserVenca {
    access_token
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserVencaGQL extends Apollo.Query<CurrentUserVencaQuery, CurrentUserVencaQueryVariables> {
    document = CurrentUserVencaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserAdminDocument = gql`
    query CurrentUserAdmin {
  CurrentUserAdmin {
    access_token
    ...CurrentUserAdmin
  }
}
    ${CurrentUserAdminFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserAdminGQL extends Apollo.Query<CurrentUserAdminQuery, CurrentUserAdminQueryVariables> {
    document = CurrentUserAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation Login($data: String!, $pass: String!) {
  Login(username: $data, password: $pass) {
    ...AuthResponse
  }
}
    ${AuthResponseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetPasswordRequestCodeDocument = gql`
    mutation ResetPasswordRequestCode($email: String!) {
  ResetPasswordRequestCode(email: $email)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetPasswordRequestCodeGQL extends Apollo.Mutation<ResetPasswordRequestCodeMutation, ResetPasswordRequestCodeMutationVariables> {
    document = ResetPasswordRequestCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetPasswordValidateCodeDocument = gql`
    mutation ResetPasswordValidateCode($code: String!, $email: String!) {
  ResetPasswordValidateCode(code: $code, email: $email)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetPasswordValidateCodeGQL extends Apollo.Mutation<ResetPasswordValidateCodeMutation, ResetPasswordValidateCodeMutationVariables> {
    document = ResetPasswordValidateCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetPasswordChangePasswordDocument = gql`
    mutation ResetPasswordChangePassword($password: String!, $code: String!, $email: String!) {
  ResetPasswordChangePassword(password: $password, code: $code, email: $email)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetPasswordChangePasswordGQL extends Apollo.Mutation<ResetPasswordChangePasswordMutation, ResetPasswordChangePasswordMutationVariables> {
    document = ResetPasswordChangePasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangePasswordDocument = gql`
    mutation ChangePassword($password: String!) {
  ChangePassword(password: $password)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangePasswordGQL extends Apollo.Mutation<ChangePasswordMutation, ChangePasswordMutationVariables> {
    document = ChangePasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }