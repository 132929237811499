import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LanguageComponent} from "./language.component";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {FormsModule} from "@angular/forms";
import {httpTranslateLoader} from '../../core/translate/translate-loader-factory';
import {MatSelectModule} from "@angular/material/select";
import {MatListModule} from "@angular/material/list";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatIcon, MatIconModule} from "@angular/material/icon";
import {FlexLayoutModule} from "@angular/flex-layout";
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [
    LanguageComponent
  ],
    imports: [
        FormsModule,
        TranslateModule.forRoot(),
        CommonModule,
        FlexLayoutModule,
        MatButtonToggleModule,
        MatListModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        RouterModule
    ],
  exports: [
    LanguageComponent
  ],
  providers: [
    {
      provide: TranslateLoader,
      useFactory: httpTranslateLoader,
      deps: [HttpClient]
    },
    TranslateService
  ]
})
export class LanguageModule {
}
